import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import BlogDetails from "@/components/blog-details";

const AboutPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Sobre">
          <HeaderOne />
          <PageBanner title="Jornada" name="Sobre mim" />
          {/*<AboutOne />
          <TestimonialsOneCarousel />
          <SubscribeForm />
          <TeamCarousel />*/}
          {/*<VideoOne />
          <FeatureTabOne />
          <CallToActionOne extraClassName="ready" />*/}
          <BlogDetails />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default AboutPage;
