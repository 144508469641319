import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SinglePostCard from "@/components/single-post-card";

const BlogDetails = () => {
  return (
    <section className="commonSection blogDetails">
      <Container>
        <Row>
          <Col lg={12}>
            <SinglePostCard />
          </Col>          
        </Row>
      </Container>
    </section>
  );
};

export default BlogDetails;
