import React, { Fragment } from "react";
import blogDetailsImage from "@/images/blog/3s.png";

const SinglePostCard = () => {
  return (
    <Fragment>
      <div className="single_blog_new" styleClass="display: flex">
        <div class="col-lg-6">
          <div className="blog_thumb">
            <img src={blogDetailsImage} alt="" />
          </div>
          <div className="blog_headings">
            <span className="blog_date">31 maio</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div className="blog_headings">
            <h3>O começo de tudo</h3>
            {/*<p className="blog_metas">
              <a href="#">By SevenHeads</a>-<a href="https://www.sevenheads.com.br/">30 Likes</a>-
              <a href="#">2 Comments</a>
            </p>*/}
          </div>
          <div className="blog_details">
            <p>
              Tudo começou quando, aos meus 10 anos, minha família comprou o primeiro computador. 
              Esse evento despertou meu interesse pela tecnologia. Na época, eu morava com minha mãe, meus dois tios e minha tia. 
              Todos em casa já haviam tido algum contato com a área, exceto minha tia, que trabalhava mais com administração e documentos. 
              Minha mãe tinha experiência em reparos de placas de celular, notebook e placa mãe, enquanto 
              um dos meus tios era especialista em formatação, backups e solução de problemas como falta de imagem ou falha na inicialização. 
              Já o outro tio estava cursando Ciência da Computação na faculdade.
            </p>            
          </div>
        </div>                
      </div>
      <div className="blog_headings">
        <h3>Primeiro contato com a programação</h3>
      </div>
      <div className="blog_details col-lg-12">
        <p>
          Aos meus 14 anos, comecei a pesquisar sobre programação e já tinha algum contato com HTML, CSS e JavaScript. 
          No entanto, a minha verdadeira imersão na programação começou quando conheci o Visualg, um interpretador de algoritmos em português. 
          Foi aí que despertei uma paixão pela programação, ao perceber as possibilidades que poderiam ser alcançadas.
          Durante o meu ensino médio, tive a oportunidade de aprimorar meus conhecimentos, pois cursei o ensino médio técnico em informática. 
          Nesse período, pude aprofundar meus conhecimentos em Visualg e C, mas ainda não tinha experiência em conexão com banco de dados ou a 
          comunicação entre o backend e o frontend. Meu foco estava principalmente no ambiente DOS, com entrada e saída de dados de forma visual.
          No entanto, estava ciente de que havia muito mais a aprender e crescer na área da programação.
        </p>            
      </div>
      <div className="blog_headings">
        <h3>Faculdade - Ciência da Computação</h3>
      </div>
      <div className="blog_details col-lg-12">
        <p>
          Assim que concluí o ensino médio, ingressei na faculdade, pois já tinha claro o que queria fazer: um curso voltado para programação. 
          Optei por Ciência da Computação, onde tive a oportunidade de aprender Java e diversas disciplinas de lógica e matemática.
          Durante a graduação, enfrentei alguns desafios, sendo a análise e complexidade de algoritmos a matéria que mais me trouxe dificuldades. 
          No entanto, as demais disciplinas foram mais tranquilas de acompanhar
        </p>            
      </div>
      <div className="blog_headings">
        <h3>Primeiro Emprego - SIQ</h3>
      </div>
      <div className="blog_details col-lg-12">
        <p>
          Ainda durante a faculdade, por volta do terceiro semestre (cerca de um ano e meio a dois anos de faculdade), 
          consegui meu estágio e primeiro emprego. No início, por ser tímido, demorei um pouco para me enturmar com os colegas de trabalho, 
          mas todos eram muito legais e acolhedores.
          Durante os dois anos em que fui estagiário, meu trabalho consistia em prestar suporte, atendendo chamados relacionados a problemas ou 
          dúvidas sobre o software da empresa. Essa experiência me ajudou a aprimorar minha escrita, devido às respostas e comunicações mais formais 
          necessárias nos atendimentos aos clientes.
          Na empresa, especializada em gestão de qualidade, adquiri conhecimentos sobre diversas metodologias, como FMEA (PFMEA e DFMEA), 
          Fluxo de Processo, RNC (5 Porquês), métodos de análise de não conformidades e solução de problemas (8D, 6D e 4D), Inspeção de Recebimento, 
          Plano de Controle, Processo de Aprovação (APQP, PAPP) e Avaliação de Fornecedores.
          Pouco antes de completar os dois anos de estágio, tive a oportunidade de fazer um teste para me tornar desenvolvedor. 
          Passei um mês desenvolvendo e realizando atividades como um desenvolvedor em atividades do dia a dia, para que pudessem avaliar minha performance. 
          Felizmente, obtive sucesso no teste e adquiri bastante conhecimento na área.
          Permaneci na empresa por 5 anos e meio, guardando boas recordações e amizades que mantenho até hoje.
        </p>
        <h5>Meu maior erro</h5>
        <p>
          Um dos maiores erros que cometi durante minha época de estagiário foi quando recebi um chamado de um cliente solicitando a desativação de todos os 
          fornecedores. O problema foi que esse cliente tinha diversas plantas localizadas em vários lugares ao redor do mundo. Infelizmente, não levei em 
          consideração quais eram as plantas específicas para desativação e acabei executando um comando 'update' sem o uso de 'where'. 😢
          Infelizmente, não era possível desfazer essa ação, pois o commit já havia sido realizado e não havia como reverter o problema com um novo 'update'. 
          A única solução viável foi utilizar um backup que, por sorte, havia sido feito próximo à data em que executei o script.
          Esse incidente serviu como uma valiosa lição para sempre tomar cuidado e verificar minuciosamente os detalhes antes de executar comandos 
          que possam ter um impacto significativo. A partir desse episódio, passei a adotar uma abordagem mais cautelosa e atenta em todas as minhas atividades.
        </p>
        <h5>Meu maior acerto</h5>
        <p>
          Na minha opinião, meu maior acerto foi o desenvolvimento de um sistema relacionado à inspeção de produtos. 
          Anteriormente, o cliente precisava preencher manualmente os dados de uma inspeção com base em um PDF gerado, onde cada item tinha um valor específico. 
          Isso exigia que o cliente consultasse o documento, identificasse os itens de inspeção e inserisse os dados correspondentes, para diferentes tipos de produtos 
          e em várias inspeções.
          Desenvolvi uma solução para automatizar esse processo. Utilizando um FTP onde o cliente enviava os PDFs, o sistema identificava a 
          inspeção com base em um identificador presente no arquivo. Em seguida, percorria o PDF de acordo com um padrão genérico que criei para lidar 
          com diferentes tipos de arquivos, extraindo os itens de inspeção e seus valores. Esses dados eram então preenchidos automaticamente no sistema. 
          Se todos os itens estivessem corretos, a inspeção era considerada aprovada; caso contrário, era marcada como reprovada. Caso algum item da inspeção 
          ainda não tivesse sido realizado, ele ficava em estado de 'aguardando inspeção'.
          Essa automação trouxe ganhos significativos em termos de eficiência e redução de erros. 
          Por esse motivo, considero esse desenvolvimento como meu maior acerto durante o tempo que trabalhei na empresa.
        </p>
      </div>
      <div className="blog_headings">
        <h3>Segundo e atual emprego - Positron/Stoneridge</h3>
      </div>
      <div className="blog_details col-lg-12">
        <p>
          Conheci a empresa por meio do LinkedIn e aceitei a oportunidade de enfrentar os desafios que surgiriam dentro da organização. 
          Durante a entrevista, tive a chance de ter uma conversa muito interessante, na qual pude conhecer mais a fundo as tecnologias utilizadas pela empresa. 
          Percebi que poderia agregar valor com o conhecimento que possuía.
          Sempre tive a sorte de trabalhar com pessoas e equipes incríveis, e aqui não foi diferente. 
          Todas as pessoas que conheci foram amigáveis e pude aprender muito com elas. 
          Enquanto na empresa anterior havia muitos profissionais experientes em um determinado assunto, aqui encontrei uma diversidade de especialistas em diferentes áreas. Isso me permitiu adquirir conhecimento em diversos tópicos e também compartilhar minhas habilidades.
          Cada projeto em que atuo é uma nova oportunidade de aprendizado, assim como cada pessoa que conheço dentro da empresa. 
          Estou feliz por fazer parte desse time e continuar contribuindo para o crescimento da organização.
        </p>
        <h5>Meu maior erro</h5>
        <p>
          Acredito que não cometi erros técnicos significativos durante o meu trabalho. 
          No entanto, se eu tivesse que apontar algo, seria em relação a algumas falhas de comunicação e alinhamento.
          É importante ressaltar que a comunicação efetiva e o alinhamento claro são fundamentais para o bom funcionamento de qualquer equipe ou projeto. 
          Em determinados momentos, percebi que houve algumas lacunas nesses aspectos, o que pode ter impactado a eficiência e a coordenação das atividades.
          Aprendi com essas situações e tenho me esforçado para melhorar constantemente a comunicação e o alinhamento com minha equipe e demais stakeholders. 
          Reconheço a importância de estar atento a esses aspectos e estou comprometido em aprimorá-los cada vez mais.
          Acredito que, ao reconhecer nossos pontos de melhoria e buscar soluções, podemos evoluir como profissionais e contribuir para um ambiente de trabalho 
          mais eficiente e produtivo.
        </p>
        <h5>Meu maior acerto</h5>
        <p>
          Tive a oportunidade de trabalhar em diversos projetos interessantes, mas dois deles se destacam para mim. O primeiro foi lidar com a implementação da 
          LGPD (Lei Geral de Proteção de Dados) nos sistemas da empresa. Esse foi o meu primeiro grande desafio ao ingressar na organização e foi gratificante 
          contribuir para a conformidade com a legislação.
          No entanto, o meu maior acerto até o momento tem sido a atividade de gerenciamento de agendamento para os operadores da empresa. 
          Resumidamente, essa atividade consiste em determinar quais agendamentos cada operador deve realizar ou já está realizando. 
          Um dos aspectos importantes desse trabalho é automatizar o processo de atribuição de agendamentos para os operadores.
          Além disso, tive a oportunidade de realizar um desenvolvimento significativo relacionado à integração com uma seguradora. 
          Embora tenha envolvido vários desafios, considero esses projetos como os mais marcantes até agora.
          Estou empolgado com as oportunidades de crescimento contínuo na empresa e ansioso para contribuir com novos projetos emocionantes no futuro.
        </p>                    
      </div>
      <div className="blog_headings">
        <h3>SevenHeads</h3>
      </div>
      <div className="blog_details col-lg-12">
        <h5>O que é ?</h5>
        <p>
          É uma empresa que criei em homenagem a todos os desenvolvedores que passaram e me ajudaram na minha jornada.
          O objetivo é atender demandas, criar soluções e ajudar empresas e pessoas com as tecnologias que possuo conhecimento 
          ou novas tecnologias se assim for necessário.
        </p>
        <h5>O que aprendi</h5>
        <p>
          Quando você trabalha em uma empresa como desenvolvedor algumas coisas já estão prontas e outras não tem a necessidade de aprender por já
          ter uma pessoa especifica para fazer alguma das tarefas.
          E foi assim que tive a oportunidade de aprender e realizar diversas tarefas relacionadas à infraestrutura e configuração de serviços. 
          Adquiri conhecimentos em subir páginas no ar, configurar DNS, gerenciar serviços de e-mail, obter domínios e configurar servidores. 
          Além disso, sempre busquei pesquisar e utilizar serviços gratuitos que atendessem aos objetivos que eu buscava alcançar.
        </p>         
      </div>
    </Fragment>
  );
};

export default SinglePostCard;
